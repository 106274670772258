@font-face {
  font-family: "George Rounded";
  src: url("./fonts/GeorgeRounded.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  overflow-y: scroll;
}

h2 {
  font-size: 48px;
  font-family: "George Rounded", sans-serif;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #000;

  @media screen and (max-width: 768px) {
    font-size: 36px;
  }
}

h3 {
  font-size: 32px;
  font-family: "George Rounded", sans-serif;
}
p ul li b {
  font-family: "Libre Franklin", sans-serif;
}

li {
  margin-bottom: 5px;
}

a {
  color: #000;
}

p a:hover {
  text-decoration: none;
  color: #ce0e2d;
}

button {
  border: none;
  background-color: transparent;
  color: #000;
}

section {
  margin-bottom: 2rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.tooltip {
  position: absolute;
  background: #53284f;
  color: #fff;
  padding: 8px;
  border-radius: 15px;
  font-family: "Libre Franklin", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  z-index: 1000;
}

/* Navbar */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fdb714;
  padding: 10px 20px;
  border-radius: 0 0 20px 20px;
  position: relative;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it sits above other content */
  box-shadow: 0px 2px 2px 0px #00000040;
}

.navbar-logo img {
  height: 40px;
  margin-top: 10px;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.navbar-links a {
  color: black;
  text-decoration: none;
  margin-left: 20px;
  font-weight: bold;
  position: relative;
}

.navbar-links ::after {
  content: "";
  position: absolute;
  bottom: -2px; /* Adjust as necessary to position the underscore closer to the text */
  left: 0;
  right: 0;
  bottom: -6px;
  border-radius: 2px;
  height: 2px; /* Adjust height to make it look like an underscore */
  background-color: #fdb714;
  width: 100%;
  display: block; /* Ensure the underscore is a block element */
  transition: 0.3s ease-in-out;
}

.navbar-links .active-link::after {
  content: "";
  text-decoration: underline;
  position: absolute;
  bottom: -2px; /* Adjust as necessary to position the underscore closer to the text */
  left: 0;
  right: 0;
  bottom: -6px;
  border-radius: 2px;
  height: 2px; /* Adjust height to make it look like an underscore */
  background-color: #000;
  width: 100%;
  display: block; /* Ensure the underscore is a block element */
  transition: 0.3s ease-in-out;
}

.navbar-links :hover::after {
  z-index: -3;
  background-color: #fed78f;
  height: 30px;
  transition: 0.3s ease-in-out;
}

.navbar-hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .navbar-links {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    flex-direction: column;
    background-color: #fdb714;
    display: none;
    border-radius: 0 0 20px 20px;
  }

  .navbar-links.open {
    display: flex;
    padding-bottom: 12px;
  }

  .navbar-links a {
    margin: 10px 0;
  }

  .navbar-hamburger {
    display: block;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .navbar-links {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    flex-direction: column;
    background-color: #fdb714;
    display: none;
    border-radius: 0 0 20px 20px;
  }

  .navbar-links.open {
    display: flex;
  }

  .navbar-links a {
    margin: 10px 0;
  }

  .navbar-hamburger {
    display: block;
  }
}

/* footer.css */
.footer {
  background-color: #fdb714;
  padding: 20px;
  text-align: left;
  border-radius: 15px 15px 0px 0px;
}

.footer p {
  font-size: 13px;
  max-width: 70%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.footer-content {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.footer-description {
  display: flex;
  flex-direction: column;
  flex: 5;
}

.footer-all-links {
  display: flex;
  justify-content: space-between;
  flex-grow: 2;
  font-family: "Libre Franklin", sans-serif;
  margin-top: 2.5rem;

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
}

.footer-links,
.footer-more-links {
  flex: 1;
  padding-right: 10px;

  @media screen and (max-width: 768px) {
    flex: 1.3;
  }
}

/* Adding a hover state to all footer links */
.footer-links a:hover,
.footer-more-links a:hover,
.footer-social a:hover {
  text-decoration: underline;
}

.footer-social {
  flex: 1;
}

.footer-links h4,
.footer-more-links h4,
.footer-social h4 {
  font-size: 16px;
  margin-bottom: 10px;
}

.footer-all-links a {
  font-size: 14px;
}

.footer-links a,
.footer-more-links a,
.footer-social a {
  display: block;
  margin-bottom: 5px;
  color: #000;
  text-decoration: none;
}

.footer-bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.footer-bottom p,
.footer-bottom a {
  margin: 0;
  font-size: 14px;
  color: #414042;
}

.footer-bottom a {
  text-decoration: none;
  color: #414042;
}

.footer-bottom a:hover {
  text-decoration: underline;
  color: #414042;
}

.hero-section {
  text-align: center;
  padding: 20px 20px 40px;
  background-color: #ffffff;
  max-width: 700px;
  margin: 0 auto;
}

.content {
  max-width: 1000px;
  margin: 0 auto;
}

/* Adding new width for the Style Guide*/
.style-guide {
  max-width: 1200px;
  margin: 0 auto;
}

.hero-section h1 {
  font-family: "George Rounded", sans-serif;
  font-size: 48px;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.hero-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #414042;
  color: #fff;
  text-decoration: none;
  border-radius: 50px; /* Fully rounded corners */
  font-weight: bold;
  transition: 0.3s ease-in-out;
}

.hero-button:hover,
.donate-button:hover,
.back-button:hover {
  background-color: #ce0e2d;
  color: #fff;
}

.featured-section {
  background-color: #fdb714;
  padding: 0 50px 50px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 0 30px 30px;
  }
}

.featured-terms-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: #000;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 20px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.featured-terms {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 25px;
  margin: 0 auto;
}

.featured-term-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.explore-guide-link {
  text-decoration: none;
  font-weight: bold;
  color: #551a8b;
  display: flex;
  align-items: center;
}

.hero-button::after {
  content: "→";
  margin-left: 5px;
}

.term-card {
  background-color: #fed78f;
  border-radius: 10px;
  padding: 20px;
  width: inherit;
  border: 0.5px solid #414042;
  box-shadow: 0px 2px 2px 0px #00000040;
}

.term-card-long {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  flex: 1;
  width: 600px;
  margin: 0 auto;
  border: 1px solid #414042;
  margin-bottom: 10px;
}

.term {
  font-size: 24px;
  font-family: "George Rounded", sans-serif;
}

.category-icon {
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
  font-family: "Libra Franklin", sans-serif;
  font-size: 12px;
  border-radius: 50px;
  color: #000;
  text-transform: uppercase;
}

.filter-section .category-icon:hover {
  opacity: 80%;
  transition: 0.1s ease-in-out;
}

.description {
  margin: 12px 0 24px;
}

.read-more-link {
  font-weight: bold;
  position: relative; /* Enables the use of ::after pseudo-element */

  @media screen and (max-width: 768px) {
    white-space: nowrap;
    margin-left: 8px;
  }
}

.read-more-link:hover {
  text-decoration: none;
  color: #ce0e2d; /* Change this to your desired hover color */
}

.dedication-section {
  margin: auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  text-align: left;
}

.dedication-content {
  display: flex;
  flex-basis: 50%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.dedication-section h2 {
  margin-bottom: 20px;
  flex-grow: 1;
}

.dedication-section p {
  font-size: 20px;
  line-height: 1.5em;
  margin-bottom: 20px;
  font-family: "Libre Franklin", sans-serif;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.dedication-section a {
  color: #000;
}

.dedication-section blockquote {
  font-family: "George Rounded", sans-serif;
  font-size: 24px;
  margin: 20px 0;
  padding: 20px;
  border-left: 3px solid #ce0e2d;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}

.fuhrmann-image {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px;
  width: 100%;
  max-width: 300px;
}

.fuhrmann-image img {
  border-radius: 10px;
  max-width: 100%;
}

.donate-button,
.back-button {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  font-family: "Libre Franklin", sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  border-radius: 50px; /* Fully rounded corners */
  padding: 8px 24px 8px 12px;
  transition: 0.3s ease-in-out;
}

.donate-button a,
.back-button a {
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
}

.support-section {
  background-color: #53284f; /* Purple background */
  color: #fff; /* White text color */
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 5rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 3.5rem;
    margin-bottom: -2rem;
  }
}

.support-section p {
  margin: 0;
  font-size: 24px;
  line-height: 1.5;
  font-family: "George Rounded", sans-serif;
  padding: 24px;
  max-width: 65%;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    max-width: 100%;
    padding: 0 0 15px 0;
  }
}

.donate-button::before {
  content: "➔"; /* Arrow symbol */
  color: white;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ce0e2d; /* Red background color */
  border-radius: 50%;
  margin-right: 10px;
}

.back-button::before {
  content: "←";
  color: white;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ce0e2d; /* Red background color */
  border-radius: 50%;
  margin-right: 10px;
}

.style-guide-page {
  display: flex;
  flex-direction: column;
}

.style-guide-dropdown,
.style-guide-dropdown-arrow {
  display: none;

  @media screen and (max-width: 1000px) {
    display: inline-block;
    vertical-align: middle;
  }
}

.style-guide-dropdown-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 7.7rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  border-top: 5px solid #000;
  border-radius: 0px 0px 15px 15px;

  @media screen and (max-width: 768px) {
    top: 6rem;
  }
}

.style-guide-dropdown-menu a,
.style-guide-dropdown-menu button {
  padding: 5px 10px;
}

.side-panel-header {
  font-size: 24px;
  font-family: "George Rounded", sans-serif;
  margin-bottom: 0.8rem;

  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.side-panel-header-mobile {
  display: none;

  @media screen and (max-width: 1000px) {
    display: block;
    font-size: 18px;
    font-weight: bold;
    font-family: "George Rounded", sans-serif;
    margin-right: 10px;
  }
}

.style-guide-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 6px solid #000;
  background-color: #fff;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 73px;
  z-index: 500; /* Ensure it sits above other content */
  margin-left: 20px;
  margin-right: 20px;
}

.style-guide-content {
  display: flex;
  width: 100%;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.side-panel {
  min-width: 380px;
  max-width: 380px;
  margin: 0 0 0 20px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 195.83px; /* Adjust this value as needed */
  height: 100vh; /* Ensure it takes up the full viewport height */
  overflow-y: auto; /* Add scrolling if needed */
  padding: 16px 0 0 10px; /* Add some padding for better look */
  background-color: #fff; /* Optional: Add a background color */

  @media screen and (max-width: 1000px) {
    width: auto;
    height: auto;
    top: 201px;
    padding: 20px 0px 0px;
    margin: 0 20px 0 20px;
    min-width: unset;
    max-width: unset;
    z-index: 1;
  }

  @media screen and (max-width: 768px) {
    width: auto;
    height: auto;
    top: 173px;
    padding: 20px 0px 0px;
    margin: 0 20px 0 20px;
    min-width: unset;
    max-width: unset;
  }
}

.style-guide-button,
.add-section {
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.style-guide-dropdown-toggle {
  padding: 0;
}

.filter-section,
.sort-section,
.guide-section,
.add-section {
  margin-bottom: 20px;
  border: 0.5px solid #a7a9ac;
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
  font-size: 14px;
}

.filter-section,
.sort-section {
  gap: 10px;

  @media screen and (max-width: 1000px) {
    display: flex;
    border: none;
    padding: 0;
    margin-bottom: 10px;
  }
}

.sort-options {
  display: flex;
  gap: 15px;

  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.sort-section label {
  font-family: "Libre Franklin", sans-serif;
}

.add-section {
  background-color: #fdb714;
  border-color: #fdb714;
}

.filter-buttons {
  box-sizing: border-box;

  @media screen and (max-width: 1000px) {
    overflow-x: auto;
    white-space: nowrap;
    align-items: center;
    display: flex;
  }
}

.filter-buttons button {
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;
  vertical-align: middle;

  @media screen and (max-width: 1000px) {
    margin-bottom: 0;
  }
}

.close-btn {
  margin-left: 8px;
  cursor: pointer;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  vertical-align: center;
}

.close-btn svg {
  vertical-align: middle;
}

.sort-section label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sort-section input {
  margin-right: 10px;
}

.guide-button {
  display: block;
  padding: 10px;
  color: #fff;
  background-color: #53284f;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: left;
  cursor: pointer;
}

.main-content {
  display: flex;
  flex-grow: 1;
  margin-right: 20px;
  padding: 16px 10px 0 30px;

  @media screen and (max-width: 768px) {
    width: auto;
    padding: 20px;
    margin-right: 0;
  }
}

.style-guide-terms {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  flex-direction: column;
  width: 100%;
}

.style-guide-term-card {
  background-color: #fff;
  border: 1px solid #a7a9ac;
  border-radius: 15px;
  padding: 32px;
  float: right;
}

.style-guide-term-card p {
  font-family: "Libre Franklin", sans-serif;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 24px;
}

.featured-terms-section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.term {
  font-weight: bold;
}

.topic-sidebar {
  background-color: #e0e0e0;
  border-radius: 5px;
  padding: 5px 10px;
}

.copy-link-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #a7a9ac;
  scale: 70%;
  vertical-align: middle;
  padding: 0 5px 0 0;
}

.copy-link-button:hover {
  color: #ce0e2d;
}

.search-bar {
  display: flex;
  height: 40px;
  padding: 4px;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #a7a9ac40;
  border-radius: 1.5rem;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: "Libra Franklin", sans-serif;
  transition: 0.2s ease-in-out;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.search-bar-mobile {
  display: none;
  transition: 0.2s ease-in-out;

  @media screen and (max-width: 768px) {
    right: 0;
    display: flex;
    height: 40px;
    align-items: center;
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
    flex-direction: row-reverse;
    background-color: #dcddde;
    transition: 0.2s ease-in-out;
  }
}

.search-bar.expanded {
  max-width: 50%;
  transition: 0.2s ease-in-out;
}

.search-bar-button-mobile {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

.search-bar input {
  border: none;
  background: transparent;
  outline: none;
  flex-grow: 1;
  padding: 0.5rem;
  font-size: 1rem;
}

.search-bar-mobile input {
  position: absolute;
  background-color: #dcddde;
  outline: none;
  height: 40px;
  left: 0;
  right: 0;
  padding: 0.5rem 0 0.5rem 3rem;
  font-size: 1rem;
  border-radius: 1.5rem;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}

.search-bar button,
.search-bar-mobile button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.search-bar button svg,
.search-bar-mobile button svg {
  width: 1rem;
  height: 1rem;
  color: #666;
  z-index: 1;
}

.about-page,
.history-and-approach {
  padding: 20px;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.column {
  flex: 1;
}

@media screen and (max-width: 768px) {
  .column:nth-child(1) p:last-child {
    margin-bottom: 0px;
  }
}

.annotated-card {
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.annotated-card-mobile {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
}

.name-box {
  display: grid;
  grid-auto-flow: column;
  padding: 10px 20px 20px;
  background: #fdb714;
  border-radius: 15px;
}

.two-columns-container {
  display: flex;
  flex-direction: row;
  gap: 48px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
}

.intro-text {
  font-size: 20px;
  font-family: "Libre Franklin", sans-serif;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}

.special-thanks {
  padding: 2rem 3rem 3rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(9, auto);
}

@media screen and (max-width: 768px) {
  .special-thanks {
    padding: 1rem 2rem 2rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(17, auto);
  }
}

.committee {
  padding: 2rem 3rem 3rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, auto);

  @media screen and (max-width: 768px) {
    padding: 1rem 2rem 2rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, auto);
  }
}

.name-box p {
  margin-bottom: 0;
  padding-right: 5px;
}

.page-headline {
  margin-top: 2rem;
  text-align: center;

  @media screen and (max-width: 768px) {
    text-align: left;
  }
}

.page-headline-left {
  margin-top: 2rem;
  text-align: left;
}

/* About page */
.team-section {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.team-section a {
  text-decoration: none;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  background: #fdb714;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-basis: 25%;
  flex-grow: 0; /* Prevent items from growing */
  flex-shrink: 0; /* Prevent items from shrinking */
  text-align: center;
  border-radius: 15px;
  padding-bottom: 1rem;

  @media screen and (max-width: 768px) {
    flex-basis: 30%;
  }
  @media screen and (max-width: 560px) {
    flex-basis: 45%;
  }
}

.card img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%; /* Fixed width */
  height: 250px; /* Fixed height */
  object-fit: cover;

  @media screen and (max-width: 768px) {
    height: 180px; /* Fixed height */
  }
}

.card p {
  font-size: 0.9em;
}

.card a {
  display: flex;
  vertical-align: bottom;
  margin-top: 10px;
  text-decoration: none;
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

.card a:hover {
  text-decoration: underline;
}

.member-name {
  font-family: "George Rounded", sans-serif;
  font-size: 22px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: left;
  padding: 8px 16px 0px;
}

.member-bio {
  font-size: 13px;
  text-align: left;
  padding: 0px 16px;
  font-family: "Libre Franklin", sans-serif;
  flex-grow: 1;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

a.bio-link {
  padding: 0px 16px 4px;
}

.member-short-bio {
  display: none;
  flex-grow: 1;

  @media screen and (max-width: 768px) {
    display: block;
    font-size: 12px;
    text-align: left;
    padding: 0px 16px;
  }
}

.about-section {
  padding-top: 5px;
}

.further-resources {
  padding-left: 20px;
  padding-right: 20px;
}

.section-headline {
  margin-bottom: 0;
}

.further-resources li {
  padding-right: 20px;
}

.further-resources li a:hover {
  text-decoration: none;
  color: #ce0e2d;
}
